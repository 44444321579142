import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["iframe"];

  shrink_to_fit() {
    const iframe = this.iframeTarget;

    if (iframe.clientWidth == 0) {
      return;
    }

    const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
    const scrollWidth = iframeDoc.body.scrollWidth;
    const clientWidth = iframe.clientWidth;
    let scale = clientWidth / scrollWidth;

    if (scale > 1.0) {
      return;
    }

    // I don't know why we need to subtract, but -0.02 seems to ensure there's no horizontal scrollbar
    scale -= 0.02;
    const width = 100.0 / scale;

    iframe.style.transform = `scale(${scale})`;
    iframe.style.transformOrigin = "top left";
    iframe.style.width = `${width}%`;

    // Calculate the scaled height based on the document's scroll height
    const scaledHeight = iframeDoc.body.scrollHeight * scale;
    // Get the parent element's height
    const parentHeight = iframe.parentElement.clientHeight / scale;

    // Use the smaller of the two values to prevent overflow
    iframe.style.height = `${Math.min(scaledHeight, parentHeight)}px`;
  }
}
