import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.scrollToLastMessage();
  }

  scrollToLastMessage() {
    const scrollContainer = this.element.querySelector("[data-turbo-scroll-container]");
    if (scrollContainer) {
      scrollContainer.scrollTop = scrollContainer.scrollHeight;
    }
  }
}
