import { Controller } from "@hotwired/stimulus";
import { Turbo } from "@hotwired/turbo-rails";

export default class extends Controller {
  static targets = ["chats", "container", "dropdown", "currentChat"];

  connect() {
    this.handleClick = this.handleClick.bind(this);
    this.handleKeyup = this.handleKeyup.bind(this);
  }

  disconnect() {
    document.removeEventListener("click", this.handleClick);
    document.removeEventListener("keydown", this.handleKeyup);
  }

  showDropdown() {
    if (!this.dropdownTarget.classList.contains("hidden")) return;

    this.getRecentChats();
    this.dropdownTarget.classList.remove("hidden");
    this.chatsTarget.classList.remove("hidden");
    this.currentChatTarget.classList.add("hidden");
    document.addEventListener("click", this.handleClick);
    document.addEventListener("keydown", this.handleKeyup);
  }

  // Can remove this once we use turbo to update in the chat agent execution interface.
  getRecentChats() {
    fetch("/chats/recent", {
      headers: {
        Accept: "text/vnd.turbo-stream.html",
      },
    })
      .then((response) => response.text())
      .then((html) => {
        Turbo.renderStreamMessage(html);
      });
  }

  hideDropdown() {
    this.dropdownTarget.classList.add("hidden");
    document.removeEventListener("click", this.handleClick);
    document.removeEventListener("keydown", this.handleKeyup);
  }

  handleClick(event) {
    if (!this.containerTarget.contains(event.target)) {
      this.hideDropdown();
    }
  }

  handleKeyup(event) {
    if (event.key === "Escape") {
      this.hideDropdown();
    }
  }
}
