import { Turbo } from "@hotwired/turbo-rails";

function isElementInViewport(el) {
  const container = el.closest("[data-turbo-scroll-container]");

  const elRect = el.getBoundingClientRect();
  const containerRect = container.getBoundingClientRect();

  return (
    elRect.top >= containerRect.top &&
    elRect.left >= containerRect.left &&
    elRect.bottom <= containerRect.bottom &&
    elRect.right <= containerRect.right
  );
}

Turbo.StreamActions.scroll_to = function () {
  const target = this.targetElements[0];

  if (!target) {
    return;
  }

  if (isElementInViewport(target)) {
    return;
  }
  target.scrollIntoView({ behavior: "smooth" });
};

Turbo.StreamActions.add_class = function () {
  const classNames = this.getAttribute("class").split(" ");
  this.targetElements.forEach((target) => {
    classNames.forEach((className) => {
      target.classList.add(className);
    });
  });
};

Turbo.StreamActions.remove_class = function () {
  const className = this.getAttribute("class");
  this.targetElements.forEach((target) => {
    target.classList.remove(className);
  });
};

Turbo.StreamActions.check_checkbox = function () {
  this.targetElements.forEach((target) => {
    target.checked = true;
  });
};

// From https://blog.corsego.com/custom-turbo-stream-actions
// <turbo-stream action="redirect" target="/projects"><template></template></turbo-stream>
// turbo_stream.action(:redirect, projects_path)
Turbo.StreamActions.redirect = function () {
  Turbo.visit(this.target);
};

Turbo.StreamActions.insert_after_first = function () {
  this.removeDuplicateTargetChildren();
  this.targetElements.forEach((e) => e.insertBefore(this.templateContent, e.firstChild.nextSibling));
};
