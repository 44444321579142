// app/javascript/controllers/prefetch_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["frame"];
  static values = {
    delay: { type: Number, default: 50 }, // Optional delay in ms
  };

  // Triggered on mouseenter
  prefetch() {
    if (this.hasFrameTarget) {
      clearTimeout(this.hoverTimeout);

      this.hoverTimeout = setTimeout(() => {
        const frame = this.frameTarget;

        if (frame && frame.hasAttribute("src") && frame.getAttribute("loading") === "lazy") {
          frame.setAttribute("loading", "eager");
        }
      }, this.delayValue);
    }
  }

  // Cancel prefetch on mouseleave
  cancelPrefetch() {
    clearTimeout(this.hoverTimeout);
  }
}
