import AutoSubmit from "@stimulus-components/auto-submit";

export default class extends AutoSubmit {
  static values = {
    delay: {
      type: Number,
      default: 100,
    },
  };

  static targets = ["checkbox"];

  submit() {
    super.submit();
    this.checkboxTarget.blur();
    this.checkboxTarget.classList.add("border-green-500!", "!focus:border-green-500");
    setTimeout(() => {
      this.checkboxTarget.classList.remove("border-green-500!", "ring-green-300!", "!focus:border-green-500");
    }, 1200);
  }
}
