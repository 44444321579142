import TextareaAutogrow from "stimulus-textarea-autogrow";

export default class extends TextareaAutogrow {
  connect() {
    super.connect();
  }

  autogrow() {
    if (this.element.scrollHeight == 0) {
      return;
    }
    this.element.style.height = "auto"; // Force re-print before calculating the scrollHeight value.
    this.element.style.height = `${this.element.scrollHeight}px`;
  }
}
