import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.scrollToLastChat();
  }

  scrollToLastChat() {
    const chats = this.element.querySelectorAll(".chat");
    const lastChat = chats[chats.length - 1];
    if (lastChat) {
      lastChat.scrollIntoView();
    }
  }
}
